import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/disable-selection';

import { ASSESSMENT_CONCHECKLIST, ASSESSMENT_CONTRACT, ASSESSMENT_DILIGENCE, ASSESSMENT_INHERENT, ASSESSMENT_OFFBOARDING, ASSESSMENT_ONBOARDING, ASSESSMENT_RESIDUAL, ASSESSMENT_REVIEW, CRITICALITY_QUESTION_GUID, DATATABLE_ALL } from './constants';
import { ajaxPromise, displayNotification, highlight, logerror, post, setTimeoutPromise } from './utils';

const initAssessmentEdit = () => {
	// Assessment Edit

	const assessment = JSON.parse(atob($('#assessment_edit_form').data('js'))) as {
		type: number;
		cat: number | null;
		cset_id: number | null;
		service_id: number | null;
	};

	let sectionObjs = [];
	let conditionals = [];
	let questionsDt: DataTables.Api = null;

	//System for only executing function when all active promises are done
	let promiseQueue = [];
	let promiseDispatch = [];
	let promiseId = 0;
	const queuePromise = (promise) => {
		const myId = ++promiseId;
		promiseQueue.push({ id: myId });
		promise().then(() => {
			promiseQueue = promiseQueue.filter(({ id }) => id !== myId);
			if (!promiseQueue.length && promiseDispatch.length) {
				promiseDispatch.forEach((fn) => fn());
				promiseDispatch = [];
			}
		});
	};
	const dispatchFn = (fn) => {
		if (promiseQueue.length) promiseDispatch.push(fn);
		else fn();
	};

	if ($('#assessment_edit_cancel').length == 1) {
		$('#assessment_edit_cancel')
			.off('click')
			.on('click', ({ target }) => {
				const $btn = $(target);
				$btn.prop('disabled', true);
				let postData = null;
				switch (assessment.type) {
					// Inherent
					case ASSESSMENT_INHERENT:
						postData = { start: 'inherent' };
						break;
					// Due Diligence & Periodic Review
					// it was sending params as [Object: object] so I have to encode / decode...
					case ASSESSMENT_RESIDUAL:
					case ASSESSMENT_REVIEW:
						postData = { start: 'vendorcategories', params: JSON.stringify({ cat_id: assessment.cat }) };
						break;
					// Onboarding & Offboarding
					case ASSESSMENT_ONBOARDING:
						postData = { start: 'onboarding' };
						break;
					case ASSESSMENT_OFFBOARDING:
						postData = { start: 'offboarding' };
						break;
					case ASSESSMENT_CONCHECKLIST:
						postData = { start: 'contract_checklist' };
						break;
					case ASSESSMENT_CONTRACT:
						postData = { start: assessment.service_id ? 'contract_categories' : 'contract_risk' };
						break;
				}
				post('/ui/setup', postData);
				setTimeout(() => $btn.prop('disabled', false), 1000);
			});
	}

	const qcats = {};
	$('#assessment_section_questions_filter_category > option').each((_, element) => {
		qcats[$(element).val()] = $(element).text();
	});

	const assessmentGet = (postData) =>
		ajaxPromise('/data/assessment_get', {data: JSON.stringify(postData)})
			.then((res) => {
				if (res.rc !== 'OK') {
					displayNotification('Assessment Edit', 'An error occurred while loading the assessment.', 'danger');
					return;
				}

				assessmentBuild(res.data);
			})
			.catch((error) => {
				logerror('assessment get error [ajax]', error);
			});

	const getSectionObj = (element) => {
		const section = $(element).is('section') ? element : $(element).closest('section').get(0);
		return sectionObjs.find(({ $section }) => $section.get(0) === section);
	};

	const questionEditor = (sectionObj, ques) => {
		$('#assessment_edit_question_text').val(ques.surveyq_text);
		$('#assessment_edit_question_description').val(ques.surveyq_description);
		if (ques.surveyq_cat != null) $('#assessment_edit_question_category').val(parseInt(ques.surveyq_cat));
		else $('#assessment_edit_question_category').val(0);

		if (ques.surveyq_guid == null) $('#assessment_edit_question_delete').hide();
		else $('#assessment_edit_question_delete').show();

		const isConditional = ques.surveyq_hidden != null && parseInt(ques.surveyq_hidden) == 1;
		$('#assessment_edit_question_hidden').prop('checked', isConditional);
		$('#assessment_edit_question_hidden').closest('.form-group').show();

		$('#assessment_edit_question_hidden')
			.off('click')
			.on('click', ({ target }) => {
				if ($(target).prop('checked')) $('#assessment_edit_answers').find('.answer-trigger').parent().hide();
				else $('#assessment_edit_answers').find('.answer-trigger').parent().show();
			});

		// BUILD OUT ANSWERS HERE
		$('#assessment_edit_answers').html('');
		const answers = ques.answers || [];

		const filteredConditionals = conditionals
			.map((cond) => ({ ...cond, questions: cond.questions.filter((q) => q.guid != ques.surveyq_guid) })) //Remove question being edited from list
			.filter((cond) => cond.questions.length); //Make sure there is no blank sections if this question being edited was removed
		const conditionalOptionsHtml = filteredConditionals
			.map(({ title, questions }) => `
				<optgroup label="${title && title.trim() != '' ? title : 'Untitled Section'}">
					${questions.map((ques) => `<option value="${ques.guid}">${ques.text}</option>`).join('')}
				</optgroup>
			`)
			.join('');

		/*
			Allow opening the question editor even if the question was somehow saved without answers.
			The later validation checks should prevent saving without them.
		*/

		const deleteAnswerIds = [];
		const buildAnswerObj = (answer) => {
			const $answer = $('#assessment_edit_answer_template > div').clone(true);
			$answer.find('.answer-trigger').html(conditionalOptionsHtml).multiselect({
				numberDisplayed: 0,
			});
			$answer.find('.answer-trigger').hide();
			if (answer) {
				$answer.find('.answer-text').val(answer.survqa_text);
				$answer.find('.answer-value').val(answer.survqa_value);
				if (answer.survqa_triggers.length > 0) $answer.find('.answer-trigger').multiselect('select', answer.survqa_triggers);
			}

			$answer.find('.answer-delete').on('click', ({ target }) => {
				$(target).closest('.form-group').remove();
				if (answer) deleteAnswerIds.push(answer.survqa_id);
			});

			// Conditional
			const isConditional = $('#assessment_edit_question_hidden').is(':checked');
			if (isConditional) $answer.find('.answer-trigger').parent().hide();

			$('#assessment_edit_answers').append($answer);

			answerValueValidation($answer.find('.answer-value'));

			return {
				$answer,
				answer,
			};
		};
		const answersObjs = answers.map((answer) => buildAnswerObj(answer));

		// Question Editor modal handlers
		$('#assessment_edit_question_answers_add')
			.off('click')
			.on('click', () => answersObjs.push(buildAnswerObj(null)));

		$('#assessment_edit_answers_container').show();

		const title = sectionObj.surveysection_text;
		if (title != '' && title != null) $('#assessment_edit_question_label > small').html($('#assessment_title').html() + ' - ' + title);
		else $('#assessment_edit_question_label > small').html($('#assessment_title').html() + ' - Section #' + (sectionObj.$section.index() + 1));

		$('#assessment_edit_question_modal').modal({ backdrop: 'static' });
		ratingsThresholdCount();

		if (ques.surveyq_guid == CRITICALITY_QUESTION_GUID) {
			$('#assessment_edit_question_hidden').closest('.form-group').hide();
			$('#assessment_edit_question_hidden').off('click');
		}

		$('#assessment_edit_question_delete')
			.off('click')
			.on('click', () => {
				if (ques.surveyq_guid == CRITICALITY_QUESTION_GUID) {
					displayNotification('Question Delete', 'The Criticality question cannot be deleted.', 'danger');
					return;
				}

				$('#assessment_edit_question_actions').hide();
				$('#assessment_edit_question_delete_confirm_actions').fadeIn();

				$('#assessment_edit_question_delete_cancel').prop('disabled', true);
				$('#assessment_edit_question_delete_confirm').prop('disabled', true);

				const deleteClear = () => {
					$('#assessment_edit_question_actions').fadeIn();
					$('#assessment_edit_question_delete_confirm_actions').hide();
					$('#assessment_edit_question_delete_cancel').off('click');
					$('#assessment_edit_question_delete_confirm').off('click');
				};

				setTimeout(() => {
					$('#assessment_edit_question_delete_cancel').prop('disabled', false);
					$('#assessment_edit_question_delete_cancel').on('click', () => deleteClear());

					$('#assessment_edit_question_delete_confirm').prop('disabled', false);
					$('#assessment_edit_question_delete_confirm').on('click', () => {
						const postData = {
							...assessment,
							section_id: +sectionObj.surveysection_id,
							guid: ques.surveyq_guid,
						};
						ajaxPromise('/form/submit', { type: 'assessment_question_delete', data: JSON.stringify(postData), json: true })
							.then((res) => {
								if (res.rc === 'HARDCODE') {
									displayNotification('Question Delete', 'The Criticality question cannot be deleted.', 'danger');
									return;
								}

								if (res.rc !== 'OK') {
									displayNotification('Question Delete', 'An error occurred while sending this request.', 'danger');
									return;
								}

								displayNotification('Question Edit', 'Question deleted successfully', 'success');
								assessmentBuild(res.data.data);
								ratingsThresholdCount();
								$('#assessment_edit_question_modal').modal('hide');
							})
							.catch((error) => {
								logerror('assessment question delete error [ajax]', error);
							});

						deleteClear();
					});
				}, 1000);
			});

		$('#assessment_edit_question_saveclose')
			.off('click')
			.on('click', ({ target }) => {
				const $btn = $(target);
				$btn.prop('disabled', true);
				setTimeout(() => $btn.prop('disabled', false), 1000);

				const postData: any = {
					...assessment,
					section_id: +sectionObj.surveysection_id,
					guid: ques.surveyq_guid,
					text: $('#assessment_edit_question_text').val().toString().trim(),
					description: $('#assessment_edit_question_description').val().toString().trim(),
					hidden: $('#assessment_edit_question_hidden').is(':checked') ? 1 : 0,
					answers: [],
					qcat: +$('#assessment_edit_question_category').val() || null, // the question category
				};

				postData.answers = $('#assessment_edit_answers > .form-group')
					.toArray()
					.map((element) => {
						const { answer, $answer } = answersObjs.find(({ $answer }) => $answer.get(0) === element);
						return {
							id: answer ? +answer.survqa_id : null,
							text: $answer.find('textarea.answer-text').val().trim(),
							value: $answer.find('input.answer-value').val().trim(),
							triggers: !postData.hidden ? $answer.find('select.answer-trigger').val() : undefined,
						};
					});

				if (postData.text.length == 0) {
					displayNotification('Question Edit', 'Please add the question text.', 'danger');
					return;
				}

				if (postData.answers.length == 0) {
					displayNotification('Question Edit', 'Please add at least one answer choice.', 'danger');
					return;
				}

				const badAnswer = postData.answers.find((answer) => answer.text.trim() == '' || answer.value == '' || isNaN(answer.value));
				if (badAnswer) {
					displayNotification('Question Edit', 'Please fill out each answer with response text and numerical value.', 'danger');
					return;
				}

				if (deleteAnswerIds.length) postData.delete_answer_ids = deleteAnswerIds;

				if (postData.qcat == null || postData.qcat == 0) {
					displayNotification('Question Edit', 'Please categorize this question.', 'danger');
					return;
				}

				ajaxPromise('/form/submit', { type: 'assessment_question_save', data: JSON.stringify(postData), json: true })
					.then((res) => {
						if (res.rc === 'HARDCODE') {
							displayNotification({
								context: 'Question Edit',
								msg: 'The Criticality question requires "Yes" and "No" as the only two responses.',
								style: 'danger',
								time: 6000,
							});
							return;
						}

						if (res.rc !== 'OK') {
							displayNotification('Question Edit', 'An error occurred while sending this request.', 'danger');
							return;
						}

						displayNotification('Question Edit', 'Question saved successfully', 'success');
						assessmentBuild(res.data.data);
						$('#assessment_edit_question_modal').modal('hide');
						ratingsThresholdCount();
					})
					.catch((error) => {
						logerror('assessment question save error [ajax]', error);
					});
			});
	};

	const itemEditor = (sectionObj, ques) => {
		$('#assessment_edit_item_text').val(ques.surveyq_text);
		$('#assessment_edit_item_description').val(ques.surveyq_description);

		$('#assessment_edit_item_delete').off('click').hide();
		if (ques.surveyq_guid) $('#assessment_edit_item_delete').show().on('click', async () => {
			const deleteClear = () => {
				$('#assessment_edit_item_actions').fadeIn();
				$('#assessment_edit_item_delete_confirm_actions').hide();
				$('#assessment_edit_item_delete_cancel').off('click');
				$('#assessment_edit_item_delete_confirm').off('click');
			};

			$('#assessment_edit_item_actions').hide();
			$('#assessment_edit_item_delete_confirm_actions').fadeIn();

			$('#assessment_edit_item_delete_cancel').prop('disabled', true);
			$('#assessment_edit_item_delete_confirm').prop('disabled', true);

			await setTimeoutPromise(1000);

			$('#assessment_edit_item_delete_cancel').prop('disabled', false);
			$('#assessment_edit_item_delete_cancel').on('click', () => deleteClear());

			$('#assessment_edit_item_delete_confirm').prop('disabled', false).on('click', async () => {
				deleteClear();

				try {
					const postData = {
						...assessment,
						section_id: +sectionObj.surveysection_id,
						guid: ques.surveyq_guid,
					};
					const res = await ajaxPromise('/form/submit', { type: 'assessment_question_delete', data: JSON.stringify(postData), json: true });
					if (res.rc !== 'OK') throw res;

					displayNotification('Item Edit', 'Item deleted successfully', 'success');
					assessmentBuild(res.data.data);
					$('#assessment_edit_item_modal').modal('hide');
				} catch (error) {
					displayNotification('Item Delete', 'An error occurred while sending this request.', 'danger');
					logerror('assessment item delete error [ajax]', error);
				}
			});
		});

		$('#assessment_edit_item_saveclose').off('click').on('click', async ({ target }) => {
			const $btn = $(target);
			$btn.prop('disabled', true);

			try {
				const postData: any = {
					...assessment,
					section_id: +sectionObj.surveysection_id,
					guid: ques.surveyq_guid,
					text: $('#assessment_edit_item_text').val().toString().trim(),
					description: $('#assessment_edit_item_description').val().toString().trim(),
				};
	
				if (postData.text.length == 0) {
					displayNotification('Item Edit', 'Please add the item text.', 'danger');
					return;
				}
	
				const res = await ajaxPromise('/form/submit', { type: 'assessment_question_save', data: JSON.stringify(postData), json: true });
				if (res.rc !== 'OK') throw res;

				displayNotification('Item Edit', 'Item saved successfully', 'success');
				assessmentBuild(res.data.data);
				$('#assessment_edit_item_modal').modal('hide');
			} catch (error) {
				displayNotification('Item Edit', 'An error occurred while sending this request.', 'danger');
				logerror('assessment item save error [ajax]', error);
			}

			$btn.prop('disabled', false);
		});

		$('#assessment_edit_item_modal').modal({ backdrop: 'static' });
	};

	const assessmentBuildQuestion = (sectionObj, ques) => {
		// build out question table row
		const isConditional = ques.surveyq_hidden != null && parseInt(ques.surveyq_hidden) == 1;
		const $question = $(`
			<tr class="assessment-question" data-guid="${ques.surveyq_guid}">
				<td>
					<i class="${ques.surveyq_guid == CRITICALITY_QUESTION_GUID ? 'fa fa-close' : 'fa fa-bars handle'}"></i>
				</td>
				<td>
					${ques.surveyq_text}
					${ques.surveyq_cat != null ? `<br><small>${qcats[ques.surveyq_cat]}</small>` : ''}
					${isConditional ? '<br><small class="text-orange">Conditional</small>' : ''}
				</td>
				<td class="text-right">
					<button class="btn btn-sm btn-primary"><i class="fa fa-edit"></i></button>
				</td>
			</tr>
		`);
		$question.find('button').on('click', () => {
			if ([ASSESSMENT_ONBOARDING, ASSESSMENT_OFFBOARDING, ASSESSMENT_CONCHECKLIST].includes(assessment.type)) itemEditor(sectionObj, ques);
			else questionEditor(sectionObj, ques);
		});
		return $question;
	};

	const assessmentBuild = (sections) => {
		// Called from assessmentGet on page load

		// list out all conditional questions
		conditionals = []; // must reset list first!
		sections.forEach((section) => {
			const conditionalQuestions = [];
			section.questions.forEach((ques) => {
				if (parseInt(ques.surveyq_hidden) == 1) {
					conditionalQuestions.push({
						text: ques.surveyq_text,
						guid: ques.surveyq_guid,
					});
				}
			});
			if (conditionalQuestions.length) conditionals.push({ title: section.surveysection_text, questions: conditionalQuestions });
		});

		$('#assessment_sections')
			.html('')
			.sortable({
				handle: '.handle-section',
				placeholder: 'ui-state-highlight',
				forcePlaceholderSize: true,
				update: (_, ui) => {
					const sectionObj = getSectionObj(ui.item.get(0));
					if (!sectionObj) return;
					assessmentSort(+sectionObj.surveysection_id);
				},
			});

		sectionObjs = [];
		sections.forEach((section) => {
			const $section = $('#assessment_edit_section_template > section').clone(true);
			const sectionObj = { ...section, $section };
			$section.find('.assessment_edit_section_title').val(section.surveysection_text);
			$section.find('.assessment_edit_section_description').val(section.surveysection_description);
			const $tbody = $section.find('table.assessment_edit_section_questions > tbody');

			let $critQuestion = null;
			if (section.questions.length) {
				section.questions.forEach((ques) => {
					const $question = assessmentBuildQuestion(sectionObj, ques);
					if (ques.surveyq_guid == CRITICALITY_QUESTION_GUID) $critQuestion = $question;
					$tbody.append($question);
				});
			} else {
				//Append empty question
				const $tr = $('<tr><td><i class="fa fa-bars" style="visibility: hidden;"></i></td><td>No questions currently</td><td></td></tr>');
				$tbody.append($tr);
			}
			$tbody.sortable({
				handle: '.handle',
				placeholder: 'ui-state-highlight',
				dropOnEmpty: true,
				connectWith: '.assessment_edit_section_questions>tbody', // Cannot drag onto empty atm.
				update: (event, ui) => {
					if (event.target !== ui.item.parent()[0]) return; //item was moved to a different section, let that section handle the udpate callback
					if ($critQuestion) $critQuestion.remove().prependTo($tbody);
					const sectionObj = getSectionObj(ui.item[0]);
					if (!sectionObj) return;
					setTimeout(() => questionOrderSave({ sectionObj, surveyq_guid: $(ui.item).data('guid') }), 250);
				},
			});
			$('#assessment_sections').append($section).show();

			$section.find('.assessment_edit_section_delete').on('click', ({ target }) => {
				const $btn = $(target);
				$btn.prop('disabled', true);
				dispatchFn(() => {
					if (sectionObj.surveysection_id == 96) {
						displayNotification('Section Delete', 'This section is required and cannot be deleted.', 'danger');
						return;
					}

					const section = sectionObjs.find(({ surveysection_id }) => surveysection_id == sectionObj.surveysection_id);
					const title = section.surveysection_text;
					if (title != '' && title != null) $('#assessment_edit_section_delete_modal').find('.modal-body').html(`Are you sure you wish to delete this section "${title}"?`);
					else $('#assessment_edit_section_delete_modal').find('.modal-body').html('Are you sure you wish to delete this section?');

					$('#assessment_edit_section_delete_modal_confirm')
						.off('click')
						.on('click', () => {
							const postData = {
								...assessment,
								section_id: +section.surveysection_id,
							};
							ajaxPromise('/form/submit', { type: 'assessment_section_delete', data: JSON.stringify(postData), json: true })
								.then((res) => {
									if (res.rc === 'HARDCODE') {
										displayNotification('Section Delete', 'This section is required and cannot be deleted.', 'danger');
										$('#assessment_edit_section_delete_modal_confirm').off('click');
										$('#assessment_edit_section_delete_modal').modal('hide');
										return;
									}

									if (res.rc !== 'OK') {
										displayNotification('Section Delete', 'An error occurred while sending this request.', 'danger');
										return;
									}

									displayNotification('Section Edit', 'Section deleted successfully', 'success');
									section.$section.slideUp(400, () => {
										section.$section.remove();
										sectionObjs = sectionObjs.filter(({ surveysection_id }) => surveysection_id !== section.surveysection_id); //Remove self from array
										ratingsThresholdCount();
									});
									$('#assessment_edit_section_delete_modal_confirm').off('click');
									$('#assessment_edit_section_delete_modal').modal('hide');
								})
								.catch((error) => {
									logerror('assessment section delete error [ajax]', error);
								});
						});

					$('#assessment_edit_section_delete_modal').modal();
					setTimeout(() => $btn.prop('disabled', false), 1000);
				});
			});

			sectionObjs.push(sectionObj);
		});

		const assessmentSort = (section_id: number) => {
			const sectionIds = [];
			$('#assessment_sections')
				.find('section')
				.each((_, element) => {
					const sectionObj = getSectionObj(element);
					if (!sectionObj) return;
					sectionIds.push(+sectionObj.surveysection_id);
				});

			if (!sectionIds.length) {
				displayNotification('Assessment Edit', 'There are no sections to save.', 'danger');
				return;
			}

			const postData = {
				...assessment,
				section_id,
				sections: sectionIds,
			};
			setTimeout(() => {
				ajaxPromise('/form/submit', { type: 'assessment_save', data: JSON.stringify(postData), json: true })
					.then((res) => {
						if (res.rc !== 'OK') {
							displayNotification('Assessment Edit', 'An error occurred while sending this request.', 'danger');
							return;
						}

						displayNotification('Assessment Edit', 'Assessment saved successfully', 'success');
						assessmentBuild(res.data.data); // needed for conditional list updating.
					})
					.catch((error) => {
						logerror('assessment save error [ajax]', error);
					});
			}, 100);
		};

		ratingsThresholdCount();
	};

	$('#assessment_edit_answers').sortable({
		handle: '.handle',
		placeholder: 'ui-state-highlight',
		forcePlaceholderSize: true,
	});

	// Section Add
	$('#assessment_edit_section_add')
		.off('click')
		.on('click', () => {
			ajaxPromise('/form/submit', { type: 'assessment_section_create', data: JSON.stringify(assessment), json: true })
				.then((res) => {
					if (res.rc !== 'OK') {
						displayNotification('Section Edit', 'An error occurred while sending this request.', 'danger');
						return;
					}

					displayNotification('Section Edit', 'New section created.', 'success');

					assessmentBuild(res.data.data);
					const sectionObj = sectionObjs.find(({ surveysection_id }) => surveysection_id == res.section_id);
					const $section = sectionObj.$section;
					$('html, body').animate({ scrollTop: $section.offset().top }, 1000);
					$section.hide();
					setTimeout(() => $section.slideDown(), 800);
				})
				.catch((error) => {
					logerror('assessment section create error [ajax]', error);
				});
		});

	
	if ([ASSESSMENT_ONBOARDING, ASSESSMENT_OFFBOARDING, ASSESSMENT_CONCHECKLIST].includes(assessment.type)) {
		// Item Add
		$('#assessment_edit_section_template')
			.find('.assessment_edit_section_item_add')
			.off('click')
			.on('click', ({ target }) => {
				const sectionObj = getSectionObj(target);
				if (!sectionObj) return;

				const question = {
					surveyq_guid: null,
					surveyq_text: '',
					surveyq_description: '',
				};
				itemEditor(sectionObj, question);
			});
	} else {
		// Question Add
		$('#assessment_edit_section_template')
			.find('.assessment_edit_section_questions_add')
			.off('click')
			.on('click', ({ target }) => {
				const sectionObj = getSectionObj(target);
				if (!sectionObj) return;
	
				const question = {
					surveyq_guid: null,
					surveyq_text: '',
					surveyq_description: '',
					surveyq_cat: null,
					surveyq_hidden: 0,
					answers: [],
				};
				questionEditor(sectionObj, question);
			});
	
		// Question Add from Template
		$('#assessment_edit_section_template')
			.find('.assessment_edit_section_questions_from_template')
			.off('click')
			.on('click', ({ target }) => {
				const sectionObj = getSectionObj(target);
				if (!sectionObj) return;
	
				sectionAddQuestions(sectionObj);
			});
	}

	$('#assessment_section_questions_filter_category')
		.off('change')
		.on('change', () => questionsDt.ajax.reload());

	const sectionAddQuestions = (sectionObj) => {
		const title = sectionObj.surveysection_text;
		if (title != '' && title != null) $('#assessment_section_questions_section_title').html($('#assessment_title').html() + ' - ' + title);
		else $('#assessment_section_questions_section_title').html($('#assessment_title').html() + ' - Section #' + (sectionObj.$section.index() + 1));

		if (questionsDt !== null) {
			questionsDt.destroy();
			questionsDt = null;
		}

		const dtOptions: DataTables.Settings = {
			ajax: {
				url: '/data/questions_load',
				data: (postData) => ({
					...postData,
					mode: DATATABLE_ALL,
					filters: {
						hidden: 0,
						type: assessment.type,
						category: $('#assessment_section_questions_filter_category').val(),
					},
				}),
			},
			buttons: [
				{
					text: 'Add',
					className: 'use ml-2 btn btn-primary',
					action: () => {
						const questions = questionsDt.rows({ selected: true }).data().toArray();
						const guids = questions.map((ques) => ques.surveyq_guid);
						const postData = {
							...assessment,
							section_id: +sectionObj.surveysection_id,
							surveyq_guids: guids,
						};
						ajaxPromise('/form/submit', { type: 'assessment_question_use', data: JSON.stringify(postData), json: true })
							.then((res) => {
								if (res.rc !== 'OK') {
									displayNotification('Section Edit', 'An error occurred while sending this request.', 'danger');
									return;
								}

								displayNotification('Section Edit', 'Questions added successfully', 'success');
								assessmentBuild(res.data.data); //truly elegant code
								$('#assessment_section_questions_modal').modal('hide');
								ratingsThresholdCount();
							})
							.catch((error) => {
								logerror('assessment question use error [ajax]', error);
							});
					},
				},
			],
			columns: [
				{
					data: null,
					defaultContent: '',
					orderable: false,
					searchable: false,
					className: 'select-checkbox',
				},
				{
					title: 'Text',
					data: 'surveyq_text',
				},
				{
					title: 'Category',
					data: 'qcat_title',
					searchable: false,
				},
				{
					title: 'Include?',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
			order: [[1, 'ASC']],
			responsive: { details: false },
			select: {
				style: 'os',
				selector: 'td:first-child',
			},
		};

		$('#assessment_section_questions_table').find('.datatables-select-all').prop('checked', false);
		questionsDt = $('#assessment_section_questions_table')
			.on('init.dt, draw.dt', () => {
				questionsDt.buttons(['.use']).disable();

				$('#assessment_section_questions_table')
					.find('.question_use')
					.off('click')
					.on('click', ({ target }) => {
						const question = questionsDt.row($(target).closest('tr')).data();
						const postData = {
							...assessment,
							section_id: +sectionObj.surveysection_id,
							surveyq_guids: [question.surveyq_guid],
						};
						ajaxPromise('/form/submit', { type: 'assessment_question_use', data: JSON.stringify(postData), json: true })
							.then((res) => {
								if (res.rc !== 'OK') {
									displayNotification('Section Edit', 'An error occurred while sending this request.', 'danger');
									return;
								}

								displayNotification('Section Edit', 'Question added successfully', 'success');
								assessmentBuild(res.data.data); //truly elegant code
								$('#assessment_section_questions_modal').modal('hide');
								ratingsThresholdCount();
							})
							.catch((error) => {
								logerror('assessment question use [ajax]', error);
							});
						$('#assessment_section_questions_modal').modal('hide');
					});
			})
			.DataTable(dtOptions);

		questionsDt.on('select', () => questionsDt.buttons(['.use']).enable());
		questionsDt.on('deselect', () => {
			if (!questionsDt.rows({ selected: true }).count()) {
				questionsDt.buttons(['.use']).disable();
			}
		});
		$('#assessment_section_questions_table').on('change', '.datatables-select-all', ({ target }) => {
			const isChecked = $(target).is(':checked');
			if (isChecked) questionsDt.rows().select();
			else questionsDt.rows().deselect();
		});

		$('#assessment_section_questions_modal').modal();
	};

	$('#assessment_edit_section_template')
		.find('.assessment_edit_section_title, .assessment_edit_section_description')
		.off('change')
		.on('change', ({ target }) => sectionSave($(target).closest('section')));

	const ratingsThresholdCount = () => {
		const count = $('#assessment_sections').find('.assessment_edit_section_questions tbody tr.assessment-question').length;
		$('#rating_thresholds_questions').html(count.toString());
	};

	const sectionSave = ($section) => {
		const sectionObj = getSectionObj($section.get(0));
		if (!sectionObj) return;

		const postData = {
			...assessment,
			section_id: +sectionObj.surveysection_id,
			text: $section.find('.assessment_edit_section_title').val().trim(),
			description: $section.find('.assessment_edit_section_description').val().trim(),
		};
		queuePromise(() =>
			setTimeoutPromise(100).then(() =>
				ajaxPromise('/form/submit', { type: 'assessment_section_save', data: JSON.stringify(postData), json: true })
					.then((res) => {
						if (res.rc !== 'OK') {
							displayNotification('Section Edit', 'An error occurred while sending this request.', 'danger');
							return;
						}

						displayNotification('Section Edit', 'Section saved successfully', 'success');
						assessmentBuild(res.data.data); // needed for conditional list updating.
					})
					.catch((error) => {
						logerror('assessment section save [ajax]', error);
					})
			)
		);
	};

	const questionOrderSave = ({ sectionObj, surveyq_guid }) => {
		const list = [];

		sectionObj.$section.find('.assessment_edit_section_questions tbody tr').each((_, element) => {
			const guid = $(element).data('guid');
			if (guid) list.push(guid);
		});

		const postData = {
			...assessment,
			section_id: +sectionObj.surveysection_id,
			questions: list,
			surveyq_guid,
		};
		setTimeout(() => {
			ajaxPromise('/form/submit', { type: 'assessment_question_order_save', data: JSON.stringify(postData), json: true })
				.then((res) => {
					if (res.rc !== 'OK') {
						displayNotification('Section Edit', 'An error occurred while sending this request.', 'danger');
						return;
					}

					displayNotification('Section Edit', 'Question order saved successfully', 'success');
					assessmentBuild(res.data.data); // needed for conditional list updating.
				})
				.catch((error) => {
					logerror('assessment question order save [ajax]', error);
				});
		}, 100);
	};

	const answerValueValidation = ($input: $) => {
		let val = parseFloat($input.val());
		if (isNaN(val)) {
			$input.val(0);
			highlight($input);
		}
		val = +val.toFixed(2); // correct to integer if
		if (val < -100 || val > 1) highlight($input);
		if ([ASSESSMENT_DILIGENCE, ASSESSMENT_REVIEW, ASSESSMENT_CONTRACT].includes(assessment.type)) {
			// if duediligence or periodicreview, limit max to 1
			val = +Math.min(Math.max(-100, val), 1).toFixed(2);
		} else {
			// other assessments don't have a "limit" so we'll limit the input to -100/100
			val = +Math.min(Math.max(-100, val), 100).toFixed(2);
		}
		$input.val(val);
	};

	$('#assessment_edit_answer_template')
		.find('.answer-value')
		.on('change', ({ target }) => answerValueValidation($(target)));

	// GO!
	assessmentGet(assessment);
};

if ($('#assessment_edit_form').length == 1) initAssessmentEdit();
