import { DATATABLE_ALL } from './constants';
import { taskForm } from './main';
import { clearTaskEditForm, loadTaskEdit } from './task_edit';
import { ajaxPromise, checkChosenChanged, displayNotification, dtColumnIndicies, getDateFilter, getDtRowData, highlight, htmlEsc, initDateFilter, logerror, post, setDateFilter, toggleSlide } from './utils';

const initTasksManager = () => {
	const initFilters = () => {
		$('#task_filter_container select.chosen').chosen({ width: '100%' });
		checkFiltersChosenChanged();
		$('#task_filter_container select.chosen').on('change', ({target}) => {
			const $select = $(target);
			checkChosenChanged($select);
		});
		toggleSlide($('#task_filter_toggle'), $('#task_filter_container'));

		initDateFilter('#task_filter_days_due');

		$('#task_filter_container select, #task_filter_container .first, #task_filter_container .second').on('change', () => taskDt.ajax.reload());

		const defaultFilters = getFilters();
		$('#task_filter_reset').off('click').on('click', () => {
			setFilters(defaultFilters);
			checkFiltersChosenChanged();
			taskDt.ajax.reload();
		});

	};

	const getFilters = () => ({
		vendor: $('#task_filter_vendor').val(),
		priorities: $('#task_filter_priority').val(),
		recurring: $('#task_filter_recurring').val(),
		recipient: $('#task_filter_recipient').val(),
		sender: $('#task_filter_sender').val(),
		tasks: $('#task_filter_tasks').val(),
		types: $('#task_filter_type').val(),
		progress: $('#task_filter_progress').val(),
		status: $('#task_filter_status').val(),
		days_due: getDateFilter('#task_filter_days_due'),
	});

	const setFilters = (filters) => {
		$('#task_filter_vendor').val(filters.vendor);
		$('#task_filter_priority').val(filters.priorities);
		$('#task_filter_recurring').val(filters.recurring);
		$('#task_filter_recipient').val(filters.recipient);
		$('#task_filter_sender').val(filters.sender);
		$('#task_filter_tasks').val(filters.tasks);
		$('#task_filter_type').val(filters.types);
		$('#task_filter_progress').val(filters.progress);
		$('#task_filter_status').val(filters.status);
		setDateFilter('#task_filter_days_due', filters.days_due);
		$('#task_filter_container select.chosen').trigger('chosen:updated');
	};

	const checkFiltersChosenChanged = () => {
		$('#task_filter_container select.chosen').each((_, element) => checkChosenChanged($(element)));
	};

	const initTaskDatatable = () => {
		const dbColumns: DataTables.ColumnSettings[] = [
			{ title: 'Vendor', data: 'vend_name', render: (val) => val === null ? '<i>N/A</i>' : htmlEsc(val) },
			{ title: 'Priority', data: 'prior_name' },
			{ title: 'Tasks', data: 'tasks', render: (val) => val === null ? '<i>N/A</i>' : htmlEsc(val) },
			{ title: 'Action Item', data: 'ttype_name' },
			{ title: 'Repeating', data: 'tk_recurring_pretty', searchable: false },
			{ title: 'Sender', data: 'sender' },
			{ title: 'Send Date', data: 'tk_senddate_pretty', searchable: false },
			{ title: 'Recipients', data: 'recipients' },
			{ title: 'Due Date', data: 'tk_duedate_pretty', searchable: false },
			{ title: 'Days Due', data: 'days_due', searchable: false },
			{ title: 'Status', data: 'tk_status_pretty' },
			{ title: 'Progress', data: 'tk_prog_pretty' },
			{ title: 'Complete Date', data: 'tk_completedate_pretty', searchable: false },
			{ title: 'Actions', data: 'actions', className: 'text-right', orderable: false, searchable: false, render: null },
		];
		const columnIndicies = dtColumnIndicies(dbColumns);
		const dtOptions: DataTables.Settings = {
			ajax: {
				url: '/data/tasks_load',
				data: (postData) => ({
					...postData,
					filters: getFilters(),
				}),
			},
			buttons: [
				{
					text: '<i class="fa fa-plus me-2"></i> Add Task',
					className: 'ml-2 btn btn-sm btn-success',
					action: () => {
						$('#task_edit_container').hide();
						taskForm({$cont: $('#task_add_container'), $submit: $('#task_add_submit'), onCreate: () => {
							closeCreateTask();
							taskDt.ajax.reload();
						}});
						$('#task_add_container').slideDown(350);
						setTimeout(() => {
							highlight($('#task_add_container'));
							$(window).scrollTop($('#task_add_container').offset().top - 400);
						}, 400);
					},
				},
				{
					text: '<i class="fa fa-file-csv me-2"></i> Export',
					className: 'ml-2 btn btn-sm btn-secondary',
					action: ({target}) => {
						const $btn = $(target);
						const filters = getFilters();
						$btn.prop('disabled', true);

						post('/export', {
							type: 'tasks',
							data: JSON.stringify(filters),
						});

						setTimeout(() => $btn.prop('disabled', false), 1000);
					},
				},
				{
					text: '<i class="fa fa-print me-2"></i> Print',
					className: 'ml-2 btn btn-sm btn-secondary',
					action: ({target}) => {
						const $btn = $(target);
						const filters = getFilters();
						$btn.prop('disabled', true);

						const params = {
							...taskDt.ajax.params(),
							filters,
						};

						post('/printout/tasks', {params: JSON.stringify(params)}, '_blank');

						setTimeout(() => $btn.prop('disabled', false), 1000);
					},
				},
			],
			columns: dbColumns,
			columnDefs: [
				{ responsivePriority: 1, targets: [columnIndicies.vend_name, columnIndicies.tk_status_pretty, columnIndicies.tk_prog_pretty] },
				{ responsivePriority: 2, targets: columnIndicies.actions },
				{ targets: '_all', render: (val) => htmlEsc(val) },
			],
			order: [[columnIndicies.tk_senddate_pretty, 'desc']],
		};
		const taskDt = $('#task_table').DataTable(dtOptions);

		$('#task_table').on('draw.dt', () => {
			const $tooltip = $('#task_table').find('.header-tooltip');
			$tooltip.tooltip({html: true});
			$tooltip.on('click', (event) => {
				event.stopPropagation();
			});
		});

		$('#task_table').on('click', '.task_go', ({ target }) => {
			const row = getDtRowData(taskDt, target);
			window.location.href = row.link;
		});
		$('#task_table').on('click', '.task_view', ({ target }) => {
			const row = getDtRowData(taskDt, target);
			taskEdit(row);
		});
		$('#task_table').on('click', '.task_manage', ({ target }) => {
			const row = getDtRowData(taskDt, target);
			taskEdit(row);
		});

		return taskDt;
	};

	const closeTaskEditForm = () => {
		const animationTime = 350;
		$('#task_edit_container').slideUp(animationTime);
		setTimeout(() => clearTaskEditForm(), animationTime);
	};

	const closeCreateTask = () => {
		$('#task_add_container').slideUp(350);
	};

	$('#task_add_close').on('click', () => closeCreateTask());

	const taskEdit = async (task) => {
		$('#task_add_container').hide();

		setTimeout(() => {
			highlight($('#task_edit_container'));
			$(window).scrollTop($('#task_edit_container').offset().top - 400);
		}, 400);
		clearTaskEditForm();

		const taskUpdated = () => {
			taskDt.ajax.reload();
			closeTaskEditForm();
		};
		loadTaskEdit({tk_guid: task.tk_guid, onUpdate: taskUpdated, mode: 'windowed'});

		$('#task_edit_close').off('click').on('click', (event) => {
			event.preventDefault();
			closeTaskEditForm();
		});

		$('#task_edit_container').slideDown(350);
	};

	const taskDt = initTaskDatatable();
	initFilters();
};

if ($('#task_manager_container').length == 1) initTasksManager();

//On print window
if ($('#tasks_printout').length == 1) {
	const deployPrintOut = async () => {
		const params = JSON.parse(atob($('#tasks_printout').data('params')));

		try {
			params.filters.mode = DATATABLE_ALL;
			const res = await ajaxPromise('/data/tasks_load', params);
			$('#spinner').show();

			const table = (
				<table className="table table-condensed table-striped" style="width: 100%;">
					<thead>
						<tr>
							{res.headers.map((col) => (
								<th>{col}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{res.data.map((row, index) => (
							<tr className={index % 2 == 0 ? 'odd' : 'even'} style="border-bottom: 1px solid #d0d0d0;">
								{row.map((cell) => (
									<td>{cell != null ? cell : ''}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			);
			$('#tasks_printout').append(table);
			$('#tasks_printout_total').html(`${res.recordsFiltered} records`);

			setTimeout(() => {
				$('#spinner').hide();
				window.print();
			}, 1000);
		}
		catch (error) {
			displayNotification('Tasks', 'There was an error loading the data.', 'danger');
			logerror('tasks get error [ajax]', error);

		}
	};

	deployPrintOut();
}